<template>
  <div
    class="favourite-listing product relative flex flex-col"
    :class="`text-${textAlign}`">
    <div
      v-if="showCross"
      class="absolute top-0 right-0 p-3 flex items-center justify-center z-1 cursor-pointer"
      @click.prevent="$emit('cross-click')">
      <svg-icon
        width="10px"
        height="10px"
        name="cross" />
    </div>
    <a
      :href="listing.link"
      :target="openInNewTab ? '_blank' : null"
      @click.prevent="goToListing">
      <div
        :class="imageBgClass"
        class="product-image">
        <img
          :src="listing.imageUrl"
          :alt="listing.name"
          class="img-responsive" />
        <div
          v-if="!disableFavourites"
          v-tippy="favouriteTippy"
          :class="{
            active: favourited,
            'js-favourite-container': allowFavouritesControls
          }"
          class="absolute js-favourite-container favourite-listing-container p-3 flex items-center justify-center text-paper-6 z-1 cursor-pointer bottom-0 right-0"
          data-cy="favourite-heart"
          v-bind="additionalDataProperties"
          @click="$emit('favourite-toggled', listing)">
          <svg-icon
            name="faveheart"
            height="16px"
            width="16px" />
          <svg-icon
            name="faveheart-filled"
            height="16px"
            width="16px" />
        </div>
      </div>
    </a>
    <div class="product-name">
      <a
        :href="listing.link"
        :target="openInNewTab ? '_blank' : null"
        @click.prevent="goToListing">
        <h4 class="product-name typeset-6 mb-1">{{ listing.name }}</h4>
        <a
          v-if="listing.designer.hasDesigner"
          :href="listing.designer.link"
          :target="openInNewTab ? '_blank' : null"
          class="typeset-7 product-designer ellipsis mb-1">
          {{ listing.designer.name }}
        </a>
        <h5 class="typeset-7 product-type-name ellipsis mb-4">
          {{ listing.product_type_name }}
        </h5>
      </a>
    </div>
    <div
      class="flex flex-wrap listing-price"
      :class="justifyPosition">
      <template v-if="listing.price.has_discount">
        <h5
          class="flex-no-shrink typeset-6"
          v-html="listing.price.actual"></h5>
        <h5
          class="flex items-center flex-no-shrink p-2 ml-3 bg-sunflower line-height-1 typeset-8 strikethrough"
          v-html="listing.price.base"></h5>
      </template>
      <h5
        v-else
        class="flex-no-shrink typeset-6"
        v-html="listing.price.actual"></h5>
    </div>
    <div
      v-if="showStockStatus && listing.outOfStock"
      class="mt-4">
      <button
        disabled
        class="btn btn-delete btn-block mb-4">
        {{ listing.stockStatus.ctaText }}
      </button>
      <div
        v-if="listing.stockStatus.longText"
        class="text-center parsed-markdown"
        v-html="listing.stockStatus.longText"></div>
    </div>
    <div
      v-if="$slots.action"
      class="mt-3">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./SvgIcon.vue";

export default {
  name: "Listing",
  components: {
    SvgIcon
  },
  props: {
    listing: {
      type: Object,
      required: true
    },
    favourited: {
      type: Boolean,
      required: true,
      default: false
    },
    disableFavourites: {
      type: Boolean,
      default: false
    },
    openInNewTab: {
      type: Boolean,
      default: false
    },
    showStockStatus: {
      type: Boolean,
      default: true
    },
    showCross: {
      type: Boolean,
      default: false
    },
    imageBgClass: {
      type: String,
      default: ""
    },
    showActionButton: {
      type: Boolean,
      default: false
    },
    actionButtonText: {
      type: String,
      default: "Remove"
    },
    trackEventName: {
      type: String,
      default: ""
    },
    allowFavouritesControls: {
      type: Boolean,
      default: false
    },
    textAlign: {
      type: String,
      default: "left"
    }
  },
  emits: ["favourite-toggled", "cross-click", "listing-clicked"],
  computed: {
    favouriteTippy() {
      return {
        content: "Remove",
        theme: "dark",
        placement: "top",
        arrow: true,
        animation: "shift-away"
      };
    },
    justifyPosition() {
      switch (this.textAlign) {
        case "left":
          return "justify-start";
        case "center":
          return "justify-center";
        case "right":
          return "justify-end";
        default:
          return "justify-start";
      }
    },
    additionalDataProperties() {
      if (!this.allowFavouritesControls) {
        return {};
      }

      return {
        "data-id": this.listing.id,
        "data-type": this.listing.type
      };
    }
  },
  methods: {
    goToListing(e) {
      if (!e.target.closest(".js-favourite-container")) {
        this.$emit("listing-clicked");
        if (this.trackEventName) {
          analytics.track(
            this.trackEventName,
            {
              id: this.listing.analytics_id
            },
            () => {
              window.location.href = this.listing.link;
            }
          );
        } else {
          window.location.href = this.listing.link;
        }
      }
    }
  }
};
</script>
